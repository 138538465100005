/* eslint-disable no-unreachable */
import { Suspense, lazy, useEffect } from 'react';
import { Navigate, useRoutes, useLocation, Routes, Route } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import PermissionRoute from '../permission/permissionRoute';
import { adminPermission, newsAgencyPermission, reportagePermission, rolePermission, sellerPermission, ticketPermission, transactionPermission } from '../permission';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router({ admin }) {

  const routesArr = [
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

  ]
/*
  return (
    <>
      <Routes>

        <Route path='auth'>
          <GuestGuard>
            <Route path={"login"} element={<Login />} />
            <Route path={"register"} element={<Register />} />
          </GuestGuard>
        </Route>

        <Route path='/' element={<DashboardLayout />}>
          <AuthGuard>
            <Route path="/" element={<Navigate to={PATH_AFTER_LOGIN} replace />} />
            <Route path="app" element={<GeneralApp />} />

            <Route path="admin">
              <Route path="/" element={<Navigate to={"/admin/list"} replace />} />
              <Route path="list" element={
                <PermissionRoute
                  element={<AdminList />}
                  permissions={adminPermission.read}
                />
              } />
              <Route path="new" element={
                <PermissionRoute
                  element={<AdminCreate />}
                  permissions={adminPermission.create}
                />
              } />
              <Route path="edit/:id" element={
                <PermissionRoute
                  element={<AdminCreate />}
                  permissions={adminPermission.update}
                />
              } />


            </Route>

            <Route path="financial">
              <Route path="/" element={<Navigate to={"/financial/list"} replace />} />
              <Route path="list" element={
                <PermissionRoute
                  element={<TransactionList />}
                  permissions={transactionPermission.read}
                />
              } />

            </Route>

            <Route path="role">
              <Route path="/" element={<Navigate to={"/role/list"} replace />} />
              <Route path="list" element={
                <PermissionRoute
                  element={<RoleList />}
                  permissions={rolePermission.read}
                />
              } />
              <Route path="new" element={
                <PermissionRoute
                  element={<CreateNewRole />}
                  permissions={rolePermission.create}
                />
              } />
              <Route path="edit/:id" element={
                <PermissionRoute
                  element={<CreateNewRole />}
                  permissions={rolePermission.update}
                />
              } />


            </Route>

            <Route path="ticket">
              <Route path="/" element={<Navigate to={"/ticket/list"} replace />} />
              <Route path="list" element={
                <PermissionRoute
                  element={<TicketList />}
                  permissions={ticketPermission.read}
                />
              } />
              <Route path="edit/:id" element={
                <PermissionRoute
                  element={<ShowTicketChat />}
                  permissions={ticketPermission.update}
                />
              } />
            </Route>

            <Route path="seller">
              <Route path="/" element={<Navigate to={"/seller/list"} replace />} />
              <Route path="list" element={
                <PermissionRoute
                  element={<SellerList />}
                  permissions={sellerPermission.read}
                />
              } />
              <Route path="new" element={
                <PermissionRoute
                  element={<CreateNewSeller />}
                  permissions={sellerPermission.create}
                />
              } />
              <Route path="edit/:id" element={
                <PermissionRoute
                  element={<CreateNewSeller />}
                  permissions={sellerPermission.update}
                />
              } />
              <Route path="show/:id" element={
                <PermissionRoute
                  element={<CreateNewSeller />}
                  permissions={sellerPermission.read}
                />
              } />


            </Route>

            <Route path="news_agency">
              <Route path="/" element={<Navigate to={"/news_agency/list"} replace />} />
              <Route path="list" element={
                <PermissionRoute
                  element={<NewsAgencyList />}
                  permissions={newsAgencyPermission.read}
                />
              } />
              <Route path="new" element={
                <PermissionRoute
                  element={<CreateNewNewsAgency />}
                  permissions={newsAgencyPermission.create}
                />
              } />
              <Route path="edit/:id" element={
                <PermissionRoute
                  element={<CreateNewNewsAgency />}
                  permissions={newsAgencyPermission.update}
                />
              } />
              <Route path="show/:id" element={
                <PermissionRoute
                  element={<CreateNewSeller />}
                  permissions={sellerPermission.read}
                />
              } />


            </Route>
          </AuthGuard>
        </Route>

      </Routes>
    </>
  ) */

  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        {
          path: 'admin',
          children: [
            { element: <Navigate to="/admin/list" replace />, index: true },
            {
              path: 'list',
              element:
                <PermissionRoute
                  element={<AdminList />}
                  permissions={adminPermission.read}
                />
            },
            {
              path: 'new',
              element:
                <PermissionRoute
                  element={<AdminCreate />}
                  permissions={adminPermission.create}
                />
            },
            {
              path: 'edit/:id',
              element:
                <PermissionRoute
                  element={<AdminCreate />}
                  permissions={adminPermission.update}
                />
            },
            // { path: 'post/:title', element: <BlogPost /> },
            // { path: 'new', element: <AdminCreate /> },
            // { path: 'edit/:id', element: <AdminCreate /> },
          ],
        },
        {
          path: 'financial',
          children: [
            { element: <Navigate to="/financial/list" replace />, index: true },
            {
              path: 'list',
              element:
                <PermissionRoute
                  element={<TransactionList />}
                  permissions={transactionPermission.read}
                />
            },
            {
              path: 'receipt',
              element:
                <PermissionRoute
                  element={<ReceiptTransactionList />}
                  permissions={transactionPermission.read}
                />
            },
            // { path: 'list', element: <TransactionList /> },
            // { path: 'post/:title', element: <BlogPost /> },
            // { path: 'new', element: <AdminCreate /> },
          ],
        },
        {
          path: 'role',
          children: [
            { element: <Navigate to="/role/list" replace />, index: true },
            {
              path: 'list',
              element:
                <PermissionRoute
                  element={<RoleList />}
                  permissions={rolePermission.read}
                />
            },
            {
              path: 'new',
              element:
                <PermissionRoute
                  element={<CreateNewRole />}
                  permissions={rolePermission.create}
                />
            },
            {
              path: 'edit/:id',
              element:
                <PermissionRoute
                  element={<CreateNewRole />}
                  permissions={rolePermission.update}
                />
            },
            // { path: 'list', element: <RoleList /> },
            // { path: 'post/:title', element: <BlogPost /> },
            // { path: 'new', element: <CreateNewRole /> },
            // { path: 'edit/:id', element: <CreateNewRole /> },
          ],
        },

        {
          path: 'ticket',
          children: [
            { element: <Navigate to="/ticket/list" replace />, index: true },
            {
              path: 'list',
              element:
                <PermissionRoute
                  element={<TicketList />}
                  permissions={ticketPermission.read}
                />
            },
            // {
            //   path: 'new',
            //   element:
            //     <PermissionRoute
            //       element={<ShowTicketChat />}
            //       permissions={ticketPermission.create}
            //     />
            // },
            {
              path: 'edit/:id',
              element:
                <PermissionRoute
                  element={<ShowTicketChat />}
                  permissions={ticketPermission.update}
                />
            },
            // { path: 'list', element: <TicketList /> },
            // { path: 'edit/:id', element: <ShowTicketChat /> },
          ],
        },

        // {
        //   path: 'special-content',
        //   children: [
        //     { element: <Navigate to="/dashboard/special-content/list" replace />, index: true },
        //     { path: 'list', element: <SpecialContentList /> },
        //     // { path: 'post/:title', element: <BlogPost /> },
        //     { path: 'new', element: <SpecialContentNew /> },
        //     { path: 'playlist/new', element: <SpecialContentNewPlaylist /> },
        //   ],
        // },

        {
          path: 'reportage',
          children: [
            { element: <Navigate to="/reportage/publish/list" replace />, index: true },
            { path: 'list', element: <ReportageList /> },
            { path: 'new', element: <CreateReportage /> },
            { path: 'edit/:id', element: <CreateReportage /> },
            { path: 'report/:id', element: <FullReportReportagePlan /> },

            { path: 'publish/list', element: <ReportagePublishList /> },
            { path: 'publish/edit/:id', element: <CreateNewPublishReportage /> },
          ],
        },
        {
          path: 'backlink',
          children: [
            { element: <Navigate to="/reportage/publish/list" replace />, index: true },
            { path: 'publish/list', element: <BacklinkPublishList /> },
            { path: 'publish/edit/:id', element: <CreateNewPublishBacklink /> },
          ],
        },
        {
          path: 'seller',
          children: [
            { element: <Navigate to="/seller/list" replace />, index: true },
            {
              path: 'list',
              element:
                <PermissionRoute
                  element={<SellerList />}
                  permissions={sellerPermission.read}
                />
            },
            {
              path: 'new',
              element:
                <PermissionRoute
                  element={<CreateNewSeller />}
                  permissions={sellerPermission.create}
                />
            },
            {
              path: 'edit/:id',
              element:
                <PermissionRoute
                  element={<CreateNewSeller />}
                  permissions={sellerPermission.update}
                />
            },
            {
              path: 'show/:id',
              element:
                <PermissionRoute
                  element={<CreateNewSeller />}
                  permissions={sellerPermission.read}
                />
            },
            {
              path: 'financial/:id',
              element:
                <PermissionRoute
                  element={<SellerFinancialList />}
                  permissions={sellerPermission.read}
                />
            },
            // { path: 'list', element: <SellerList /> },
            // { path: 'new', element: <CreateNewSeller /> },
            // { path: 'edit/:id', element: <CreateNewSeller /> },
            // { path: 'show/:id', element: <CreateNewSeller /> },
          ],
        },
        {
          path: 'reportage-package',
          children: [
            { element: <Navigate to="/reportage-package/list" replace />, index: true },
            {
              path: 'list',
              element:
                <PermissionRoute
                  element={<ReportagePackageList />}
                  permissions={reportagePermission.read}
                />
            },
            {
              path: 'new',
              element:
                <PermissionRoute
                  element={<CreatePackage />}
                  permissions={reportagePermission.create}
                />
            },
            {
              path: 'edit/:id',
              element:
                <PermissionRoute
                  element={<CreatePackage />}
                  permissions={reportagePermission.update}
                />
            },
          ],
        },
        {
          path: 'discount-code',
          children: [
            { element: <Navigate to="/discount-code/list" replace />, index: true },
            {
              path: 'list',
              element:
                <PermissionRoute
                  element={<DiscountCodeList />}
                  permissions={reportagePermission.read}
                />
            },
            {
              path: 'new',
              element:
                <PermissionRoute
                  element={<CreateNewDiscountCode />}
                  permissions={reportagePermission.create}
                />
            },
            {
              path: 'edit/:id',
              element:
                <PermissionRoute
                  element={<CreateNewDiscountCode />}
                  permissions={reportagePermission.update}
                />
            },
          ],
        },
        {
          path: 'report',
          children: [
            { element: <Navigate to="/report/reportage" replace />, index: true },
            {
              path: 'list',
              element:
                <PermissionRoute
                  element={<SellerList />}
                  permissions={sellerPermission.read}
                />
            },
            {
              path: 'reportage',
              element:
                <PermissionRoute
                  element={<ReportageReportList />}
                  permissions={adminPermission.read}
                />
            },
            {
              path: 'backlink',
              element:
                <PermissionRoute
                  element={<BacklinkReportList />}
                  permissions={adminPermission.read}
                />
            },
            // { path: 'list', element: <SellerList /> },
            // { path: 'new', element: <CreateNewSeller /> },
            // { path: 'edit/:id', element: <CreateNewSeller /> },
            // { path: 'show/:id', element: <CreateNewSeller /> },
          ],
        },
        {
          path: 'news_agency',
          children: [
            { element: <Navigate to="/news_agency/list" replace />, index: true },
            {
              path: 'list',
              element:
                <PermissionRoute
                  element={<NewsAgencyList />}
                  permissions={newsAgencyPermission.read}
                />
            },
            {
              path: 'new',
              element:
                <PermissionRoute
                  element={<CreateNewNewsAgency />}
                  permissions={newsAgencyPermission.create}
                />
            },
            {
              path: 'edit/:id',
              element:
                <PermissionRoute
                  element={<CreateNewNewsAgency />}
                  permissions={newsAgencyPermission.update}
                />
            },
            {
              path: 'plans/:id',
              element:
                <PermissionRoute
                  element={<ShowNewsAgencyPlans />}
                  permissions={newsAgencyPermission.read}
                />
            },
            {
              path: ':agencyId/reportage/new',
              element:
                <PermissionRoute
                  element={<CreateReportage />}
                  permissions={reportagePermission.create}
                />
            },
            {
              path: ':agencyId/reportage/edit/:reportageId',
              element:
                <PermissionRoute
                  element={<CreateReportage />}
                  permissions={reportagePermission.update}
                />
            },
            {
              path: ':agencyId/reportage/show/:reportageId',
              element:
                <PermissionRoute
                  element={<CreateReportage />}
                  permissions={reportagePermission.read}
                />
            },
            {
              path: ':agencyId/foreign_reportage/new',
              element:
                <PermissionRoute
                  element={<CreateReportage />}
                  permissions={reportagePermission.create}
                />
            },
            {
              path: ':agencyId/foreign_reportage/edit/:reportageId',
              element:
                <PermissionRoute
                  element={<CreateReportage />}
                  permissions={reportagePermission.update}
                />
            },
            {
              path: ':agencyId/foreign_reportage/show/:reportageId',
              element:
                <PermissionRoute
                  element={<CreateReportage />}
                  permissions={reportagePermission.read}
                />
            },
            {
              path: ':agencyId/backlink/new',
              element:
                <PermissionRoute
                  element={<CreateNewBacklink />}
                  permissions={reportagePermission.create}
                />
            },
            {
              path: ':agencyId/backlink/edit/:backlinkId',
              element:
                <PermissionRoute
                  element={<CreateNewBacklink />}
                  permissions={reportagePermission.update}
                />
            },
            {
              path: ':agencyId/backlink/show/:backlinkId',
              element:
                <PermissionRoute
                  element={<CreateNewBacklink />}
                  permissions={reportagePermission.read}
                />
            },
            
          ],
        },
        {
          path: 'managing-site',
          children: [
            { element: <Navigate to="/managing-site/general-discount" replace />, index: true },
            { path: 'general-discount', element: <GeneralVipDiscount /> },
          ],
        },
        {
          path: 'admin-log',
          children: [
            { element: <Navigate to="/admin-log/list" replace />, index: true },
            { path: 'list', element: <SystemLogList /> },
          ],
        },
        {
          path: 'notification',
          children: [
            { element: <Navigate to="/notification/list" replace />, index: true },
            { path: 'list', element: <NotificationList /> },
          ],
        },
        {
          path: 'country',
          children: [
            { element: <Navigate to="/country/list" replace />, index: true },
            { path: 'list', element: <CountryList /> },
            { path: 'new', element: <CreateNewCountry /> },
            { path: 'edit/:id', element: <CreateNewCountry /> },
          ],
        },
        {
          path: 'language',
          children: [
            { element: <Navigate to="/language/list" replace />, index: true },
            { path: 'list', element: <LanguageList /> },
            { path: 'new', element: <CreateNewLanguage /> },
            { path: 'edit/:id', element: <CreateNewLanguage /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/user/list" replace />, index: true },
            { path: 'list', element: <UserList /> },
            // { path: 'new', element: <CreateNewLanguage /> },
            { path: 'edit/:id', element: <CreateNewUser /> },
          ],
        },
        {
          path: 'client-type',
          children: [
            { element: <Navigate to="/client-type/list" replace />, index: true },
            { path: 'list', element: <ClientTypeList /> },
            { path: 'new', element: <CreateNewClientType /> },
            { path: 'edit/:id', element: <CreateNewClientType /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    // {
    //   path: '/',
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //     { path: 'about-us', element: <About /> },
    //     { path: 'contact-us', element: <Contact /> },
    //     { path: 'faqs', element: <Faqs /> },
    //   ],
    // },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const EcommerceProductCategoryList = Loadable(lazy(() => import('../pages/dashboard/productCategory/EcommerceProductCategoryList')));
const EcommerceProductCategoryCreate = Loadable(lazy(() => import('../pages/dashboard/productCategory/EcommerceProductCategoryCreate')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// BLOG , NEWS
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const AuthorList = Loadable(lazy(() => import('../pages/dashboard/news/authors/AuthorsList')));

// ADMIN
const AdminList = Loadable(lazy(() => import('../pages/admin Management/AdminList')));
const AdminCreate = Loadable(lazy(() => import('../pages/admin Management/AdminCreate')));

// ROLE
const RoleList = Loadable(lazy(() => import('../pages/role/list')));
const CreateNewRole = Loadable(lazy(() => import('../pages/role/create')));

// FINANCIAL
const TransactionList = Loadable(lazy(() => import('../pages/financial/transactions')));
const ReceiptTransactionList = Loadable(lazy(() => import('../pages/financial/receipt')));
// const AdminCreate = Loadable(lazy(() => import('../pages/admin Management/AdminCreate')));

// TICKET
const TicketList = Loadable(lazy(() => import('../pages/tickets/list')));
const ShowTicketChat = Loadable(lazy(() => import('../pages/tickets/chat')));
// const AdminCreate = Loadable(lazy(() => import('../pages/admin Management/AdminCreate')));


// SPECIAL CONTENT
const SpecialContentList = Loadable(lazy(() => import('../pages/special content/list')));
const SpecialContentNew = Loadable(lazy(() => import('../pages/special content/new')));
const SpecialContentNewPlaylist = Loadable(lazy(() => import('../pages/special content/playlist/new')));

// REPORTAGE
const CreateReportage = Loadable(lazy(() => import('../pages/reportage/create')));
const ReportageList = Loadable(lazy(() => import('../pages/reportage/list')));
const ReportagePublishList = Loadable(lazy(() => import('../pages/reportage/publish/list')));
const CreateNewPublishReportage = Loadable(lazy(() => import('../pages/reportage/publish/create')));
const ReportageReportList = Loadable(lazy(() => import('../pages/reportage/report')));
const FullReportReportagePlan = Loadable(lazy(() => import('../pages/reportage/reportPlan')));

// PACKAGES
const CreatePackage = Loadable(lazy(() => import('../pages/packages/create')));
const ReportagePackageList = Loadable(lazy(() => import('../pages/packages/list')));

// DISCOUNT CODE
const CreateNewDiscountCode = Loadable(lazy(() => import('../pages/discountCode/create')));
const DiscountCodeList = Loadable(lazy(() => import('../pages/discountCode/list')));

// BACKLINK
const CreateNewBacklink = Loadable(lazy(() => import('../pages/backlink/create')));
const BacklinkPublishList = Loadable(lazy(() => import('../pages/backlink/publish/list')));
const CreateNewPublishBacklink = Loadable(lazy(() => import('../pages/backlink/publish/create')));
const BacklinkReportList = Loadable(lazy(() => import('../pages/backlink/report')));

// SELLER
const SellerList = Loadable(lazy(() => import('../pages/seller/list')));
const CreateNewSeller = Loadable(lazy(() => import('../pages/seller/create')));
const SellerFinancialList = Loadable(lazy(() => import('../pages/seller/financial/list')));

// USER
const UserList = Loadable(lazy(() => import('../pages/user/list')));
const CreateNewUser = Loadable(lazy(() => import('../pages/user/create')));

// CLIENT TYPE
const ClientTypeList = Loadable(lazy(() => import('../pages/clientType/list')));
const CreateNewClientType = Loadable(lazy(() => import('../pages/clientType/create')));

// NEWS AGENCY
const NewsAgencyList = Loadable(lazy(() => import('../pages/newsAgency/list')));
const CreateNewNewsAgency = Loadable(lazy(() => import('../pages/newsAgency/create')));
const ShowNewsAgencyPlans = Loadable(lazy(() => import('../pages/newsAgency/plans')));

// MANAGING DATA
const GeneralVipDiscount = Loadable(lazy(() => import('../pages/generalVipDiscount')));

// Country
const CountryList = Loadable(lazy(() => import('../pages/country/list')));
const CreateNewCountry = Loadable(lazy(() => import('../pages/country/create')));

// Language
const LanguageList = Loadable(lazy(() => import('../pages/language/list')));
const CreateNewLanguage = Loadable(lazy(() => import('../pages/language/create')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
// const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// SYSTEM LOG
const SystemLogList = Loadable(lazy(() => import('../pages/systemLog/list')));

// NOTIFICATION
const NotificationList = Loadable(lazy(() => import('../pages/notification/list')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
